<template>
  <div id="contents" class="raw_materials_inspection">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li class="active"><a>원자재 수입검사</a></li>
        </ul>
      </div>
      <component :is="dynamicComponent"></component>
    </div>
  </div>
</template>

<script>
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  computed: {
    dynamicComponent() {
      if (Number(localStorage.getItem('saupKey')) == 19)
        return () =>
          import('@/views/forms/Quality/19/TaegwangMaterialVerificationForm');
      else
        return () =>
          import(
            '@/views/forms/Quality/MaterialVerification/MaterialVerificationForm'
          );
    },
  },
  created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitMaterialVerificationPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
